import { useContext } from 'react';
import { AuthContext } from '../contexts';

export function useAuth() {
    const { currentUser, userLoggedIn, loading, auth } =
        useContext(AuthContext);
    return {
        currentUser,
        userLoggedIn,
        loading,
        auth
    };
}
