import './index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './Login';
import reportWebVitals from './reportWebVitals';
import { QueryParamProvider } from 'use-query-params';
import { WindowHistoryAdapter } from 'use-query-params/adapters/window';
import * as qs from 'query-string';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    RouterProvider,
    Routes
} from 'react-router-dom';
import Login from './Login';
import { AuthProvider } from './AuthProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    // <React.StrictMode>
        <AuthProvider>
            <Router>
                <QueryParamProvider
                    adapter={WindowHistoryAdapter}
                    options={{
                        searchStringToObject: qs.default.parse,
                        objectToSearchString: qs.default.stringify
                    }}
                >
                    <Routes>
                        <Route path="/*" element={<Login />} />
                    </Routes>
                </QueryParamProvider>
            </Router>
        </AuthProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
