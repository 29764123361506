function Logo() {
    return (
        <div className="relative">
            <div className="relative bg-gradient-to-r from-primary-purple to-primary-blue text-transparent bg-clip-text p-2 flex flex-col">
                <h1 className="text-5xl font-extrabold leading-6 mx-auto">
                    YDX
                </h1>
                <h2 className="font-bold text-xl mt-2 mx-auto tracking-[0.2rem] text-justify ml-2">
                    PLAYER
                </h2>
            </div>
        </div>
    );
}
export default Logo;