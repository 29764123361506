import { useCallback, useEffect, useRef, useState } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { useQueryParam, StringParam, withDefault } from 'use-query-params';
import Logo from './Logo';
import { useAuth } from './hooks/useAuth';
import {
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    signOut,
    getAuth,
    signInWithRedirect
} from 'firebase/auth';
import { useDebouncedCallback } from 'use-debounce';
import { redirect } from 'react-router-dom';
import { FcGoogle } from 'react-icons/fc';
import { FaApple } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';

const MicrosoftLogo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
        <path fill="#f3f3f3" d="M0 0h23v23H0z" />
        <path fill="#f35325" d="M1 1h10v10H1z" />
        <path fill="#81bc06" d="M12 1h10v10H12z" />
        <path fill="#05a6f0" d="M1 12h10v10H1z" />
        <path fill="#ffba08" d="M12 12h10v10H12z" />
    </svg>
);

const modes = {
    GOOGLE: 'google.com',
    APPLE: 'apple.com',
    MICROSOFT: 'microsoft.com',
    FACEBOOK: 'facebook.com'
};

function SocialLogo({ mode, ...rest }) {
    return (
        <>
            {mode.toLowerCase() === modes.GOOGLE && <FcGoogle {...rest} />}
            {mode.toLowerCase() === modes.APPLE && <FaApple {...rest} />}
            {mode.toLowerCase() === modes.MICROSOFT && (
                <MicrosoftLogo {...rest} />
            )}
            {mode.toLowerCase() === modes.FACEBOOK && <FaFacebook {...rest} />}
        </>
    );
}

const STAGES = {
    INVALID_MODE: 'INVALID_MODE',
    LOADING: 'LOADING',
    ALREADY_LOGGED_IN: 'ALREADY_LOGGED_IN',
    USER_CONTINUATION_NEEDED: 'USER_CONTINUATION_NEEDED',
    LOGIN_ATTEMPT_UNDERWAY: 'LOGIN_ATTEMPT_UNDERWAY',
    LOGIN_SUCCESS_NEEDS_REDIRECT_TO_APP: 'LOGIN_SUCCESS_NEEDS_REDIRECT_TO_APP',
    UNKNOWN_ERROR: 'UNKNOWN_ERROR'
};

async function performLogin(auth, provider) {
    try {
        const popupResult = await signInWithPopup(auth, provider);
        return popupResult;
    } catch (e) {
        throw e;
    }
}

function Login() {
    const { loading, userLoggedIn, currentUser, auth } = useAuth();

    const modeWithDefault = withDefault(StringParam, '--');
    const [mode, setMode] = useQueryParam('mode', modeWithDefault);

    const [stage, setStage] = useState(STAGES.LOADING);
    const [userContinuationMessage, setUserContinuationMessage] =
        useState(null);

    useEffect(() => {
        const m = mode.toLowerCase();
        if (
            !(
                m === modes.GOOGLE ||
                m === modes.APPLE ||
                m === modes.FACEBOOK ||
                m === modes.MICROSOFT
            )
        ) {
            setStage(STAGES.INVALID_MODE);
        }
    }, [mode]);

    const getAuthProvider = (mode) => {
        const m = mode.toLowerCase();
        if (m === modes.GOOGLE) {
            return new GoogleAuthProvider();
        } else if (m === modes.APPLE) {
            return new OAuthProvider(modes.APPLE);
        } else if (m === modes.FACEBOOK) {
            return new FacebookAuthProvider();
        } else if (m === modes.MICROSOFT) {
            return new OAuthProvider(modes.MICROSOFT);
        }
    };

    const getAuthProviderDisplayName = (mode) => {
        const m = mode.toLowerCase();
        if (m === modes.GOOGLE) {
            return 'Google';
        } else if (m === modes.APPLE) {
            return 'Apple';
        } else if (m === modes.FACEBOOK) {
            return 'Facebook';
        } else if (m === modes.MICROSOFT) {
            return 'Microsoft';
        }
    };

    const openInApp = useCallback(async (currentUser) => {
        const token = await currentUser.getIdToken();
        console.log('ID token', token);
        window.location.href = 'ydx-player://login-callback?id-token' + token;
    }, []);

    const performAndHandleLogin = useCallback(async () => {
        const provider = getAuthProvider(mode);
        if (!provider) {
            setStage((prevStage) => {
                if (prevStage !== STAGES.INVALID_MODE) {
                    return STAGES.UNKNOWN_ERROR;
                } else {
                    return prevStage;
                }
            });
            return;
        }
        try {
            const loginRes = await performLogin(auth, provider);
            setStage((prevStage) => {
                if (
                    prevStage !== STAGES.INVALID_MODE &&
                    prevStage !== STAGES.ALREADY_LOGGED_IN &&
                    prevStage !== STAGES.UNKNOWN_ERROR
                ) {
                    return STAGES.LOGIN_SUCCESS_NEEDS_REDIRECT_TO_APP;
                } else {
                    return prevStage;
                }
            });
            openInApp(loginRes.user);
        } catch (e) {
            if (
                e.code === 'auth/popup-blocked' ||
                e.code === 'auth/cancelled-popup-request' ||
                e.code === 'auth/popup-closed-by-user'
            ) {
                setStage((prevStage) => {
                    if (
                        prevStage !== STAGES.INVALID_MODE &&
                        prevStage !== STAGES.ALREADY_LOGGED_IN &&
                        prevStage !== STAGES.UNKNOWN_ERROR
                    ) {
                        return STAGES.USER_CONTINUATION_NEEDED;
                    } else {
                        return prevStage;
                    }
                });
            } else {
                console.error(e);
                setStage((prevStage) => {
                    if (prevStage !== STAGES.INVALID_MODE) {
                        return STAGES.UNKNOWN_ERROR;
                    } else {
                        return prevStage;
                    }
                });
            }
        }
    }, [auth, mode, openInApp]);

    const initialCheckup = useCallback(async () => {
        const authX = getAuth();
        const user = authX.currentUser;
        if (user) {
            setStage((prevStage) => {
                if (
                    prevStage !== STAGES.INVALID_MODE &&
                    prevStage !== STAGES.UNKNOWN_ERROR
                ) {
                    return STAGES.ALREADY_LOGGED_IN;
                } else {
                    return prevStage;
                }
            });
            return;
        }

        //performing login
        try {
            await performAndHandleLogin();
        } catch (e) {
            //not needed just to be safe
            setStage((prevStage) => {
                if (prevStage !== STAGES.INVALID_MODE) {
                    return STAGES.UNKNOWN_ERROR;
                } else {
                    return prevStage;
                }
            });
        }
    }, [performAndHandleLogin]);

    useEffect(() => {
        //acts like componentDidMount
        initialCheckup();
    }, []);

    return (
        <main className="flex flex-col min-h-screen bg-gradient-to-b from-secondary to-primary-blue">
            <div className="mx-auto w-[30rem] bg-secondary/75 px-9 py-12 rounded-xl flex flex-col my-auto">
                <div className="mx-auto w-max">
                    <Logo />
                </div>
                {stage === STAGES.LOADING && (
                    <>
                        <div className="mx-auto mt-6">
                            <ScaleLoader
                                color="#FFFFFF"
                                radius={20}
                                height={20}
                            />
                        </div>
                        <div className="mx-auto text-white">Logging you in</div>
                    </>
                )}
                {stage === STAGES.ALREADY_LOGGED_IN && currentUser && (
                    <>
                        <div className="mx-auto font-bold text-white text-xl mt-3">
                            Log In
                        </div>
                        <div className="mx-auto p-3 bg-white rounded-md mt-7 flex flex-row w-96">
                            {currentUser && currentUser.photoURL && (
                                <div className="relative">
                                    <img
                                        referrerPolicy="no-referrer"
                                        src={currentUser.photoURL}
                                        className="relative object-contain w-16 h-16 rounded-full z-30"
                                        alt="profile"
                                    />
                                    <div className="absolute right-0 bottom-0 translate-x-2 z-20">
                                        <SocialLogo
                                            className={'text-xl'}
                                            mode={
                                                currentUser.providerData[0]
                                                    .providerId
                                            }
                                        />
                                    </div>
                                </div>
                            )}
                            <div className="grow flex flex-col pl-5">
                                <div className="text-slate-600 text-sm leading-4 mt-auto">
                                    already logged in with
                                </div>
                                <div className="font-bold mb-auto">
                                    {currentUser.email}
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => {
                                openInApp(currentUser);
                            }}
                            className="flex w-96 mx-auto mt-6 justify-center rounded-[5px] !bg-gradient-to-r from-primary-purple to-primary-blue font-secondary hover:from-primary-purple-hover hover:to-primary-blue-hover active:from-primary-purple-active active:to-primary-blue-active text-white font-normal py-3 hover:underline"
                        >
                            Continue with this Account
                        </button>
                        <div className="mx-auto text-slate-300 mt-2 mb-1 text-sm">
                            OR
                        </div>
                        <div
                            onClick={() => {
                                signOut(auth)
                                    .then((res) => {
                                        console.log(
                                            'logged out to continue...'
                                        );
                                        window.location.reload();
                                    })
                                    .catch((e) => {
                                        console.error(e);
                                    });
                            }}
                            className="mx-auto text-white cursor-pointer hover:underline"
                        >
                            Logout and continue
                        </div>
                    </>
                )}

                {stage === STAGES.LOGIN_SUCCESS_NEEDS_REDIRECT_TO_APP && (
                    <>
                        <div className="mx-auto text-white">
                            Logged In. Redirecting to the App
                        </div>
                        <div
                            onClick={() => {
                                openInApp(currentUser);
                            }}
                            className="mx-auto rounded-xl bg-gradient-to-r from-primary-purple to-primary-blue font-secondary hover:from-primary-purple-hover hover:to-primary-blue-hover active:from-primary-purple-active active:to-primary-blue-active text-white font-normal py-2 hover:underline px-10 cursor-pointer mt-5"
                        >
                            Continue in App
                        </div>
                    </>
                )}
                {stage === STAGES.USER_CONTINUATION_NEEDED && (
                    <>
                        <div className="mx-auto text-white">
                            Continue to sign in
                        </div>
                        <div
                            onClick={async () => {
                                try {
                                    await performAndHandleLogin();
                                } catch (e) {
                                    //not needed just to be safe
                                    setStage((prevStage) => {
                                        if (prevStage !== STAGES.INVALID_MODE) {
                                            return STAGES.UNKNOWN_ERROR;
                                        } else {
                                            return prevStage;
                                        }
                                    });
                                }
                            }}
                            className="mx-auto rounded-xl bg-gradient-to-r from-primary-purple to-primary-blue font-secondary hover:from-primary-purple-hover hover:to-primary-blue-hover active:from-primary-purple-active active:to-primary-blue-active text-white font-normal py-2 hover:underline px-10 cursor-pointer mt-5"
                        >
                            Sign in with{' '}
                            <span className="font-bold">
                                {getAuthProviderDisplayName(mode)}
                            </span>
                        </div>
                    </>
                )}
                {stage === STAGES.UNKNOWN_ERROR && (
                    <>
                        <div>unknown error</div>
                    </>
                )}
                {stage === STAGES.INVALID_MODE && (
                    <div className="mx-auto mt-2 text-error-text text-sm">
                        Invalid request. please check again
                    </div>
                )}
            </div>
        </main>
    );
}

export default Login;
